/* eslint-disable */
import { Separator, Text, useTheme } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GLSearchState, InitialSearchState } from "../../components/GLSearch/GLSearch.types";
import GLSearchForm, { GLSearchFormRef } from "../../components/GLSearch/GLSearchForm";
import { OrderedMap } from "immutable";
import GLDashboard from "../../components/GLDashboard/GLDashboard";
import { getGLColumns } from "../../components/GLDashboard/GLDashboard.Columns";
import { getGridItemsFromPayload } from "../../components/GLSearch/GLSearchForm.State";
import {
  ActionResult,
  ActionTypes,
  GenericActionButtonCalculator,
  GLDashboardRow,
  IActionsManagerRef,
  IIndexedTile,
  JemConfiguration,
  JEMContext,
  JemNotification,
  LoadingSpinner,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  PageHeading,
  PageStyles,
  SanitizedDashboardRow,
  UserContext,
  IDashboardGridRef,
  DashboardListActionsRef,
  DomainDataObjects,
  DomainDataEnum,
  GeneralLedgerActionResult,
  GeneralLedgerAction,
  ErrorHelper,
  ErrorMessages,
  useQuery
} from "@jem/components";
import { calculateNavigateParameters, fromURLSearchParams } from "../../components/GLSearch/GLSearch.UrlNavigation";

import GLSearchHeader from "../../components/GLSearch/GLSearch.Header";
import { isValid, set } from "date-fns";
import { gl } from "date-fns/locale";
import { augmentWithProcessingStatus } from "../../../Shared/utilities/augmentWithProcessingStatus";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

export interface GLSearchProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  mockDashboardDataFn?: MockDataFn<any>;
  environment: JemConfiguration["environment"];
}

function ActionHandler(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedDashboardRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>,
  actionName: ActionTypes
): () => void {
  return () => {
    if (actionManagerRef.current && dashboardGridRef.current) {
      const selection = dashboardGridRef.current.getSelection();
      const items = selection.items as SanitizedDashboardRow[];
      actionManagerRef.current.open(actionName, items);
    }
  };
}

// const calculateButtonStates =
//   (actions: GridAction[]) =>
//   (rows: SanitizedDashboardRow[]): IDashboardListActionsState => {
//     const initialState: IDashboardListActionsState = {
//       retryDisabled: GridCommandToggle.Removed,
//       deleteActionDisabled: GridCommandToggle.Removed,
//       saveDisabled: GridCommandToggle.Disabled,
//       sendToPosterDisabled: GridCommandToggle.Removed,
//       approveDisabled: GridCommandToggle.Disabled,
//       releaseForSignoffDisabled: GridCommandToggle.Disabled,
//       needsClarificationDisabled: GridCommandToggle.Disabled,
//       addPosterDisabled: GridCommandToggle.Disabled,
//       addReviewerDisabled: GridCommandToggle.Disabled,
//       recallDisabled: GridCommandToggle.Disabled,
//       rescindDisabled: GridCommandToggle.Disabled,
//       addAttachmentDisabled: GridCommandToggle.Disabled,
//       releaseToSAPDisabled: GridCommandToggle.Removed,
//       sendBackFromTreasuryDisabled: GridCommandToggle.Removed
//     };
//     if (!actions || rows.length === 0) {
//       return initialState;
//     }
//     // const firstStatus = rows[0].txtStatus;
//     // if (rows.some((x) => x.txtStatus !== firstStatus)) {
//     //   return initialState;
//     // }
//     const actionNameToFlag = {
//       SaveSAP: GridCommandToggle.Enabled,
//       // addAttachment
//       Attach: GridCommandToggle.Enabled,
//       // addReviewer
//       AddReviewer: GridCommandToggle.Enabled,
//       // releaseForSignoff
//       InitReview: GridCommandToggle.Enabled,
//       // approve
//       Approve: GridCommandToggle.Enabled,
//       // needsClarification
//       Clarify: GridCommandToggle.Enabled,
//       // addPoster
//       AddPoster: GridCommandToggle.Enabled,
//       // rescind
//       Rescind: GridCommandToggle.Enabled,
//       // recall
//       Recall: GridCommandToggle.Enabled,
//       // deleteAction
//       DeletePo: GridCommandToggle.Enabled
//     };
//     // const simplifiedActions = actions.reduce((ctr, action) => {
//     //   ctr[action.actionName] = action.weightage;
//     //   return ctr;
//     // }, {} as { [key: string]: number });
//     // for (const row of rows) {
//     //   for (const actionName of Object.keys(actionNameToFlag)) {
//     //     if (!(actionName in simplifiedActions)) {
//     //       actionNameToFlag[actionName as keyof typeof actionNameToFlag] = GridCommandToggle.Disabled;
//     //     } else {
//     //       const currentFlagState = actionNameToFlag[actionName as keyof typeof actionNameToFlag];
//     //       if (currentFlagState === GridCommandToggle.Enabled) {
//     //         const buttonWeightage = simplifiedActions[actionName as keyof typeof simplifiedActions] as number;
//     //         const flagState = isButtonEnabled(buttonWeightage, row.actionWeightage);
//     //         if (!flagState) {
//     //           actionNameToFlag[actionName as keyof typeof actionNameToFlag] = GridCommandToggle.Disabled;
//     //           // todo: remove action from array actionsToUse
//     //         }
//     //       }
//     //     }
//     //   }
//     // }
//     return {
//       retryDisabled: GridCommandToggle.Removed,
//       deleteActionDisabled: GridCommandToggle.Removed,
//       saveDisabled: actionNameToFlag.SaveSAP,
//       sendToPosterDisabled: GridCommandToggle.Removed,
//       approveDisabled: actionNameToFlag.Approve,
//       releaseForSignoffDisabled: actionNameToFlag.InitReview,
//       needsClarificationDisabled: actionNameToFlag.Clarify,
//       addPosterDisabled: actionNameToFlag.AddPoster,
//       addReviewerDisabled: actionNameToFlag.AddReviewer,
//       recallDisabled: actionNameToFlag.Recall,
//       rescindDisabled: actionNameToFlag.Rescind,
//       addAttachmentDisabled: actionNameToFlag.Attach,
//       releaseToSAPDisabled: GridCommandToggle.Removed,
//       sendBackFromTreasuryDisabled: GridCommandToggle.Removed
//     };
//   };

const GLSearch: React.FC<GLSearchProps> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [domainDataStatus, setDomainDataStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [currentSearchState, setCurrentSearchState] = useState<GLSearchState | undefined>(undefined);
  const logger = useContext(LoggingContext);
  const { accessToken, jemUser } = useContext(UserContext);
  const jemContext = useContext(JEMContext);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  const location = useLocation();
  const [processingStatus, setProcessingStatus] = useState<GeneralLedgerAction | null>(null);
  const theme = useTheme();
  const glSearchFormRef = useRef<GLSearchFormRef>(null);

  useEffect(() => {
    if (
      jemContext.initInfo.status === LoadingStatus.Rejected ||
      jemContext.initInfo.status === LoadingStatus.Resolved
    ) {
      setDomainDataStatus(LoadingStatus.Resolved);
    }
  }, [jemContext.initInfo.values]);

  useEffect(
    function getSearchStateFromQueryOrLocalStorage() {
      if (
        jemContext.initInfo.status === LoadingStatus.Resolved ||
        jemContext.initInfo.status === LoadingStatus.Rejected
      ) {
        console.log("GLSearch: getSearchStateFromQueryOrLocalStorage");
        const noSearchParams = searchParams.toString() === "";
        const localSearchState = localStorage.getItem("jeCurrentSearch");
        if (noSearchParams && !localSearchState) {
          return;
        }
        if (!noSearchParams) {
          const searchState = fromURLSearchParams(searchParams);
          setCurrentSearchState(searchState);
          return;
        }
        if (localSearchState) {
          setCurrentSearchState(JSON.parse(localSearchState));
        }
      }
    },
    [searchParams, jemContext.initInfo.values]
  );

  const {
    data: gridData,
    refetch: refetchGridData,
    isLoading,
    isFetching,
    error,
    dataUpdatedAt
  } = useQuery({
    queryKey: ["search", currentSearchState],
    queryFn: async (opts) => {
      const search = (opts.queryKey as [string, GLSearchState])[1] as GLSearchState;
      const gridData = await getGridItemsFromPayload(
        accessToken,
        search,
        props.configuration,
        jemContext.initInfo.values as Pick<
          DomainDataObjects,
          | DomainDataEnum.FiscalPeriods
          | DomainDataEnum.JeCompanyCodes
          | DomainDataEnum.JeTypes
          | DomainDataEnum.JeReasonCodes
          | DomainDataEnum.JeBusinessGroupInfo
        >
      );
      return gridData;
    },

    select: (data) => {
      if (!processingStatus) {
        return data;
      }
      const gridDataCopy = augmentWithProcessingStatus(data, processingStatus);
      return gridDataCopy;
    },
    enabled: !!currentSearchState && jemContext.initInfo.values !== null,
    staleTime: 1000 * 60 * 60
  });

  const loadingStatus =
    isLoading || isFetching
      ? LoadingStatus.Pending
      : error !== undefined && error !== null
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;
  const gridItems = gridData ? (gridData as SanitizedDashboardRow[]) : [];

  if (glSearchFormRef.current && loadingStatus === LoadingStatus.Resolved && gridItems.length === 0) {
    glSearchFormRef.current.expand();
  }

  const refreshTrigger = async (search: GLSearchState, notification: JemNotification) => {
    console.log("GLSearch: refreshTrigger");
    if (isEqual(search, currentSearchState)) {
      refetchGridData();
      return;
    }
    const [canNavigate, searchParams] = calculateNavigateParameters(search);
    if (canNavigate) {
      navigate({
        search: `?${createSearchParams(searchParams)}`
      });
    } else {
      localStorage.setItem("jeCurrentSearch", JSON.stringify(search));
      navigate(
        {
          search: ""
        },
        { replace: true }
      );
      setCurrentSearchState(search);
    }
  };

  const onSubmitAction = (actionResult: GeneralLedgerActionResult, _currentTile: string | null) => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }
    logger.addNotification(actionResult.notification);
    setProcessingStatus(actionResult.response);
    refetchGridData();
  };

  const refetchGridDatawithpagination = async (
    nextPageIndex: number,
    totalCount: number,
    pageSize: number,
    isExport: boolean
  ): Promise<SanitizedDashboardRow[]> => {
    if (!currentSearchState) {
      throw new Error("Current search state is undefined");
    }
    const gridData = await getGridItemsFromPayload(
      accessToken,
      currentSearchState,
      props.configuration,
      jemContext.initInfo.values as Pick<
        DomainDataObjects,
        | DomainDataEnum.FiscalPeriods
        | DomainDataEnum.JeCompanyCodes
        | DomainDataEnum.JeTypes
        | DomainDataEnum.JeReasonCodes
        | DomainDataEnum.JeBusinessGroupInfo
      >,
      nextPageIndex,
      totalCount,
      pageSize,
      isExport
    );

    return gridData;
  };
  return (
    <>
      <div className={PageStyles.rootDiv}>
        <GLSearchHeader
          lastRefreshed={dataUpdatedAt}
          onRefresh={function forceReloadGridItems() {
            refetchGridData();
          }}
          disabled={loadingStatus === LoadingStatus.Pending}
          hidden={currentSearchState === undefined}
        />
        {domainDataStatus === LoadingStatus.Resolved && jemContext.initInfo.values ? (
          <>
            <GLSearchForm
              configuration={props.configuration}
              searchState={currentSearchState}
              triggerRefreshFn={refreshTrigger}
              domainData={jemContext.initInfo.values}
              customRef={glSearchFormRef}
              environment={props.environment}
            />
            <Separator />
          </>
        ) : (
          <LoadingSpinner label="Loading Search Form" />
        )}
        {loadingStatus !== LoadingStatus.Pending && gridItems.length === 0 ? (
          <>
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          </>
        ) : jemContext.initInfo.values && currentSearchState !== undefined ? (
          <>
            <GLDashboard
              configuration={props.configuration}
              attachmentsConfiguration={props.attachmentsConfiguration}
              items={gridItems as GLDashboardRow[]}
              tilesAreLoading={LoadingStatus.Resolved}
              dataIsLoading={loadingStatus}
              uniqueIdForDashboardsColumnGenerator={"glSearchDashboardConfig"}
              columnGenerator={getGLColumns(location, theme)}
              theIndexFromTheColumnsThatIsInitallySortedWith={14}
              tiles={OrderedMap<string, IIndexedTile>({})}
              buttonCalculator={GenericActionButtonCalculator<GLDashboardRow>(
                jemContext.initInfo.values,
                jemUser.roles
              )}
              domainData={jemContext.initInfo.values}
              onAction={onSubmitAction}
              refetchPaginatedGridData={refetchGridDatawithpagination}
              searchType={currentSearchState.SearchType}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

GLSearch.displayName = "GLSearch";

export default GLSearch;
