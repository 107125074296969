import { css } from "@emotion/css";
import { IDropdownOption, MessageBar, MessageBarType, SelectionMode, Text } from "@fluentui/react";
import {
  DashboardFilter,
  DashboardGrid,
  DomainDataEnum,
  DomainDataObjects,
  exportToExcel,
  FilterSchema,
  FilterState,
  IDashboardGridProps,
  IDashboardGridRef,
  IIndexedTile,
  JemConfiguration,
  JEMContext,
  LoadingSpinner,
  LoadingStatus,
  LocalMessage,
  PageStyles,
  useAsync,
  UserContext,
  checkUsersPermissions,
  ObjectKeys,
  ApiError,
  CommonJEMError,
  FiscalPeriodStringTransformations,
  SignoffMode,
  SignoffStatus,
  FCCWTaskProperty
} from "@jem/components";
import { OrderedMap } from "immutable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getJEMChecklistDetailsColumns } from "../../components/JEMChecklistDetails/JEMChecklistDetails.Columns";
import { JEMChecklistDetailsFilterRows } from "../../components/JEMChecklistDetails/JEMChecklistDetails.filterRows";
import { JEMChecklistDetailsHeader } from "../../components/JEMChecklistDetails/JEMChecklistDetails.Header";
import {
  bulkDeleteJEMChecklistTasks,
  ChecklistDetails,
  cloneJEMChecklist,
  CloneResponse,
  CustomizedState,
  deleteJEChecklistTask,
  getChecklistDetails,
  markNoActivityWithPayload,
  publishJEMChecklistTasks,
  saveJEChecklistTask,
  bulkEditJEMChecklistTasks,
  signOffJEChecklist,
  getTaskPropertyFromFCCW
} from "../../components/JEMChecklistDetails/JEMChecklistDetails.Requests";
import { parsePublishOrNoActivityResponse } from "../../components/JEMChecklistDetails/JEMChecklistDetails.utilities";
import { JEMChecklistDetailsClonePanel } from "../../components/JEMChecklistDetails/JEMChecklistDetailsClonePanel";
import { JEMChecklistDetailsCommandBar } from "../../components/JEMChecklistDetails/JEMChecklistDetailsCommandBar";
import JEMChecklistDetailsDeletePanel from "../../components/JEMChecklistDetails/JEMChecklistDetailsDeletePanel";
import JEMChecklistDetailsEditPanel from "../../components/JEMChecklistDetails/JEMChecklistDetailsEditPanel";
import JEMChecklistDetailsNoActivityPanel from "../../components/JEMChecklistDetails/JEMChecklistDetailsNoActivityPanel";
import JEMChecklistDetailsPublishPanel from "../../components/JEMChecklistDetails/JEMChecklistDetailsPublishPanel";
import { JEMChecklistDetailsRow } from "@jem/components";
import JEMChecklistDetailsBulkDeletePanel from "../../components/JEMChecklistDetails/JEMChecklistDetailsBulkDeletePanel";
import BulkEdit from "../../components/JEMChecklistBulkEdit/BulkEdit";
import { getFormInputConfig } from "../../components/JEMChecklistBulkEdit/TaskBulkEdit.config";
import { TaskBulkEditInputDto } from "../../components/JEMChecklistBulkEdit/TaskBulkEditInput";
import { JEMChecklistDetailsImportTasksPanel } from "../../components/JEMChecklistDetails/JEMChecklistDetailsImportTasksPanel";
import JEMChecklistDetailsSignOffPanel from "../../components/JEMChecklistDetails/JEMChecklistDetailsSignOffPanel";
import useLocalStorage, { LocalStorageKeys } from "@jem/components/lib/Shared/hooks/useLocalStorage";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash/isEqual");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const kebabCase = require("lodash/kebabCase");

const checklistDetailsItem = "jem_checklistDetailsItem";

export interface JEMChecklistDetailsProps {
  configuration: JemConfiguration;
  onNavigateBack: (state: unknown) => void;
}

interface IJEMChecklistDetailsPageState {
  editItem: JEMChecklistDetailsRow | null;
  deleteItem: JEMChecklistDetailsRow | null;
  selectedRows: JEMChecklistDetailsRow[] | null;
  openCloneChecklistPanel: boolean;
  openPublishChecklistPanel: boolean;
  openNoActivityChecklistPanel: boolean;
  openBulkDeleteChecklistPanel: boolean;
  openBulkEditChecklistPanel: boolean;
  openImportTasksPanel: boolean;
  openChecklistSignOffPanel: boolean;
  loadingSave: boolean;
  loadingDelete: boolean;
  loadingPublish: boolean;
  loadingNoActivity: boolean;
  loadingClone: boolean;
  loadingBulkDelete: boolean;
  loadingBulkEdit: boolean;
  loadingChecklistSignOffPanel: boolean;
  message: LocalMessage | null;
  errorSave: string[];
  errorDelete: string[];
  errorPublish: string[];
  errorClone: string[];
  errorNoActivity: string[];
  errorBulkDelete: string[];
  errorBulkEdit: string[];
  errorChecklistSignOff: string[];
  cloneResponse: CloneResponse | null;
  signoffMode: SignoffMode | null;
  missedSLAOptions: IDropdownOption[];
  actualTimeTaken: number;
}

const dashboardFilters: {
  allFilters: FilterSchema<JEMChecklistDetailsRow>[];
  singleSelectionFilters: string[];
  searchEnabledFilters: string[];
} = {
  singleSelectionFilters: [],
  searchEnabledFilters: ["Assigned Poster", "Backup Posters", "Backup Reviewers", "Author", "Company Codes"],
  allFilters: [
    {
      filterName: "JE Type",
      columnName: "jeType"
    },
    {
      filterName: "Task Type",
      columnName: "taskType"
    },
    {
      filterName: "Published State",
      columnName: "publishedState"
    },
    {
      filterName: "Assigned Poster",
      columnName: "assignedPoster"
    },
    {
      filterName: "Backup Posters",
      columnName: "backupPoster"
    },
    {
      filterName: "Company Codes",
      columnName: "companycode"
    },
    {
      filterName: "Posting Due Date",
      columnName: "postingDueDate"
    },
    {
      filterName: "Backup Reviewers",
      columnName: "assignedReviewer"
    },
    {
      filterName: "Author",
      columnName: "author"
    },
    {
      filterName: "Reason Code",
      columnName: "reasonCodeText"
    },
    {
      filterName: "Draft Date Code",
      columnName: "draftDateCode"
    },
    {
      filterName: "Frequency",
      columnName: "frequency"
    },
    {
      filterName: "Posting Due Date",
      columnName: "postingDueDate"
    },
    {
      filterName: "Estimated Time",
      columnName: "estimatedTimeMins"
    },
    {
      filterName: "Actual Time Taken",
      columnName: "actualTimeTakenMins"
    },
    {
      filterName: "Status",
      columnName: "status"
    },
    {
      filterName: "Is F05 Posting",
      columnName: "isF05PostingString"
    }
  ]
};

function handleChecklistError(
  setPageState: React.Dispatch<React.SetStateAction<IJEMChecklistDetailsPageState>>,
  pageState: IJEMChecklistDetailsPageState,
  loadingType:
    | "loadingSave"
    | "loadingDelete"
    | "loadingPublish"
    | "loadingNoActivity"
    | "loadingClone"
    | "loadingBulkDelete"
    | "loadingBulkEdit"
    | "loadingChecklistSignOffPanel",
  errorType:
    | "errorSave"
    | "errorDelete"
    | "errorPublish"
    | "errorNoActivity"
    | "errorClone"
    | "errorBulkDelete"
    | "errorBulkEdit"
    | "errorChecklistSignOff",
  error: unknown
) {
  setPageState({
    ...pageState,
    [loadingType]: false
  });
  if (error instanceof ApiError) {
    if (error.props) {
      const obj = error.props as CommonJEMError;
      setPageState({
        ...pageState,
        [errorType]: [obj.title + ": " + obj.detail]
      });
    } else {
      setPageState({
        ...pageState,
        [errorType]: [error.message]
      });
    }
    return;
  }
  setPageState({
    ...pageState,
    [errorType]: ["Experiencing technical issues, please contact support"]
  });
}

const JEMChecklistDetailsPage: React.FC<JEMChecklistDetailsProps> = (props) => {
  const [pageState, setPageState] = useState<IJEMChecklistDetailsPageState>({
    editItem: null,
    deleteItem: null,
    selectedRows: [],
    openCloneChecklistPanel: false,
    openPublishChecklistPanel: false,
    openBulkDeleteChecklistPanel: false,
    openNoActivityChecklistPanel: false,
    openBulkEditChecklistPanel: false,
    openImportTasksPanel: false,
    openChecklistSignOffPanel: false,
    loadingSave: false,
    loadingDelete: false,
    loadingPublish: false,
    loadingNoActivity: false,
    loadingClone: false,
    loadingBulkDelete: false,
    loadingBulkEdit: false,
    loadingChecklistSignOffPanel: false,
    message: null,
    errorSave: [],
    errorDelete: [],
    errorNoActivity: [],
    errorClone: [],
    errorPublish: [],
    errorBulkDelete: [],
    errorBulkEdit: [],
    errorChecklistSignOff: [],
    cloneResponse: null,
    signoffMode: null,
    missedSLAOptions: [],
    actualTimeTaken: 0
  });
  const userContext = useContext(UserContext);
  const jemContext = useContext(JEMContext);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const [isNotRefresh, setIsNotRefresh] = useState<boolean>(true);
  const [gridItems, setGridItems] = useState<JEMChecklistDetailsRow[]>([]);
  const [filteredItems, setFilteredItems] = useState<JEMChecklistDetailsRow[]>([]);
  const [dashboardCache, setDashboardCache] = useLocalStorage<FilterState>(
    `${LocalStorageKeys.checklistDetailsFilterState}`,
    {} as FilterState
  );
  const [filterState, setFilterState] = useState<FilterState>(dashboardCache || ({} as FilterState));
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  const {
    data: chkDetailsPageState,
    error: _error,
    status: _loadingStatus,
    run
  } = useAsync<ChecklistDetails | null>({
    data: {
      fcwChecklistName: "",
      checklistName: "",
      checklistOwners: "",
      defaultReviewers: "",
      fcwTaskId: "",
      fp: -1,
      fy: -1,
      fiscalPeriod: "",
      taskList: [],
      backupPoster: [],
      backupReviewer: [],
      companyCodes: [],
      signOffStatus: "Not Ready for Signoff"
    },
    status: LoadingStatus.Idle
  });

  useEffect(() => {
    if (
      chkDetailsPageState &&
      Array.isArray(chkDetailsPageState.taskList) &&
      chkDetailsPageState.checklistName &&
      jemContext.initInfo.values &&
      ObjectKeys(jemContext.initInfo.values).length > 0
    ) {
      setLoadingStatus(LoadingStatus.Resolved);
      const localId = localStorage.getItem(checklistDetailsItem);
      if (localId !== chkDetailsPageState?.fcwTaskId) {
        setIsNotRefresh(false);
        localStorage.removeItem(LocalStorageKeys.checklistDetailsFilterState);
        setFilterState({} as FilterState);
        localStorage.setItem(checklistDetailsItem, chkDetailsPageState?.fcwTaskId || "");
        if (chkDetailsPageState.taskList) {
          setGridItems(chkDetailsPageState.taskList);
        }
      } else if (localId == chkDetailsPageState?.fcwTaskId) {
        setGridItems(chkDetailsPageState.taskList);
        setFilteredItems(
          filterState.filterBar
            ? JEMChecklistDetailsFilterRows(filterState, dashboardFilters.allFilters, chkDetailsPageState.taskList)
            : chkDetailsPageState.taskList
        );
      }
    }
  }, [chkDetailsPageState, jemContext.initInfo.values]);

  const domainData =
    jemContext.initInfo.values ||
    ({
      JeReasonCodes: [],
      FiscalPeriods: [],
      PostingDueDates: []
    } as Pick<
      DomainDataObjects,
      DomainDataEnum.PostingDueDates | DomainDataEnum.FiscalPeriods | DomainDataEnum.JeReasonCodes
    >);

  useEffect(() => {
    const value = searchParams.get("checklistRefGuid");
    run(
      getChecklistDetails(
        props.configuration.GeneralLedgerApi,
        userContext.accessToken,
        value as string,
        location.state as CustomizedState | null,
        domainData
      )
    );
  }, [searchParams]);

  const onEditClick = (editItem: JEMChecklistDetailsRow) => {
    setPageState({
      ...pageState,
      editItem
    });
  };

  const onDeleteClick = (deleteItem: JEMChecklistDetailsRow) => {
    setPageState({
      ...pageState,
      deleteItem
    });
  };

  const onRefreshClick = () => {
    const value = searchParams.get("checklistRefGuid");
    run(
      getChecklistDetails(
        props.configuration.GeneralLedgerApi,
        userContext.accessToken,
        value as string,
        location.state as CustomizedState | null,
        domainData
      )
    );
  };

  const disableSignOff = () => {
    if (!chkDetailsPageState) {
      return true;
    }
    if (!chkDetailsPageState.checklistOwners.toUpperCase().includes(userContext.user.alias.toUpperCase())) {
      return true;
    }
    if (
      chkDetailsPageState.taskList.findIndex(
        (task) => !task.docNo && !task.href && task.publishedState.toUpperCase() !== "NO ACTIVITY"
      ) >= 0
    ) {
      return true;
    }
    if (chkDetailsPageState.signOffStatus.toUpperCase() === "SIGNED OFF") {
      return true;
    }
    return false;
  };

  const onSignOffClick = () => {
    setPageState({ ...pageState, openChecklistSignOffPanel: true, signoffMode: SignoffMode.SignOff });
  };

  const disableReopen = () => {
    if (!chkDetailsPageState) {
      return true;
    }

    if (!chkDetailsPageState.checklistOwners.toUpperCase().includes(userContext.user.alias.toUpperCase())) {
      return true;
    }

    if (
      chkDetailsPageState.taskList.findIndex(
        (task) => !task.docNo && !task.href && task.publishedState.toUpperCase() !== "NO ACTIVITY"
      ) >= 0
    ) {
      return true;
    }

    if (chkDetailsPageState.signOffStatus.toUpperCase() !== "SIGNED OFF") {
      return true;
    }

    return false;
  };

  const onReopenClick = () => {
    setPageState({ ...pageState, openChecklistSignOffPanel: true, signoffMode: SignoffMode.Reopen });
  };

  const disableAllButSignOff = () => {
    if (chkDetailsPageState && chkDetailsPageState?.signOffStatus.toUpperCase() === "SIGNED OFF") {
      return true;
    }

    return false;
  };

  const columnGenerator: IDashboardGridProps["columnGenerator"] = getJEMChecklistDetailsColumns({
    onEditClick,
    onDeleteClick,
    location: location,
    disableAllButSignOff
  });

  const onCloneChecklistClick = () => {
    if (dashboardGridRef.current && chkDetailsPageState) {
      const items = dashboardGridRef.current.getSelection().items as JEMChecklistDetailsRow[];
      let adhocDraftsForCloning = items.length > 0 ? items : chkDetailsPageState.taskList;
      adhocDraftsForCloning = adhocDraftsForCloning.filter((item) => item.isAdHocDraft);
      setPageState({
        ...pageState,
        openCloneChecklistPanel: true,
        selectedRows: adhocDraftsForCloning
      });
    }
    // do nothing if there's no grid in the page
  };

  const onPublishChecklistClick = () => {
    if (dashboardGridRef.current?.getSelection().items) {
      setPageState({
        ...pageState,
        openPublishChecklistPanel: true,
        selectedRows: dashboardGridRef.current.getSelection().items as JEMChecklistDetailsRow[]
      });
    } else {
      setPageState({
        ...pageState,
        openPublishChecklistPanel: false,
        selectedRows: []
      });
    }
  };

  const onBulkDeleteChecklistClick = () => {
    if (dashboardGridRef.current?.getSelection().items) {
      setPageState({
        ...pageState,
        openBulkDeleteChecklistPanel: true,
        selectedRows: dashboardGridRef.current.getSelection().items as JEMChecklistDetailsRow[]
      });
    } else {
      setPageState({
        ...pageState,
        openBulkDeleteChecklistPanel: false,
        selectedRows: []
      });
    }
  };

  const onImportTasksClicked = () => {
    setPageState({
      ...pageState,
      openImportTasksPanel: true
    });
  };

  const onBulkEditChecklistClick = () => {
    if (dashboardGridRef.current?.getSelection().items) {
      setPageState({
        ...pageState,
        openBulkEditChecklistPanel: true,
        selectedRows: dashboardGridRef.current.getSelection().items as JEMChecklistDetailsRow[]
      });
    } else {
      setPageState({
        ...pageState,
        openBulkEditChecklistPanel: false,
        selectedRows: []
      });
    }
  };
  const onNoActivityChecklistClick = () => {
    if (dashboardGridRef.current?.getSelection().items) {
      setPageState({
        ...pageState,
        openNoActivityChecklistPanel: true,
        selectedRows: dashboardGridRef.current.getSelection().items as JEMChecklistDetailsRow[]
      });
    } else {
      setPageState({
        ...pageState,
        openNoActivityChecklistPanel: false,
        selectedRows: []
      });
    }
  };

  const closeClonePanel = (message: LocalMessage | null) => {
    setPageState({
      ...pageState,
      openCloneChecklistPanel: false,
      errorClone: [],
      loadingClone: false,
      message,
      cloneResponse: null
    });
  };

  const closePanel = () => {
    setPageState({
      ...pageState,
      editItem: null,
      loadingSave: false,
      errorSave: []
    });
  };

  const closeDeletePanel = () => {
    setPageState({
      ...pageState,
      deleteItem: null,
      errorDelete: []
    });
  };

  const closeBulkEditPanel = () => {
    setPageState({
      ...pageState,
      message: {
        message: "Bulk edit activity performed successfully.",
        type: MessageBarType.success
      },
      openBulkEditChecklistPanel: false,
      errorBulkEdit: [],
      selectedRows: []
    });
  };

  const closePublishPanel = () => {
    setPageState({
      ...pageState,
      openPublishChecklistPanel: false,
      errorPublish: [],
      selectedRows: []
    });
  };

  const closeBulkDeletePanel = () => {
    setPageState({
      ...pageState,
      openBulkDeleteChecklistPanel: false,
      errorBulkDelete: [],
      selectedRows: []
    });
  };

  const closeNoActivityPanel = () => {
    setPageState({
      ...pageState,
      openNoActivityChecklistPanel: false,
      errorNoActivity: [],
      selectedRows: []
    });
  };

  const closeSignOffPanel = () => {
    setPageState({
      ...pageState,
      openChecklistSignOffPanel: false,
      errorChecklistSignOff: [],
      selectedRows: []
    });
  };

  const setCache = () => {
    const { currentTile, searchCriteria, filterBar } = filterState;
    setDashboardCache({ currentTile, searchCriteria, filterBar });
  };

  useEffect(() => {
    setCache();
  }, [filterState]);

  useEffect(() => {
    if (gridItems) {
      setFilteredItems(
        filterState.filterBar
          ? JEMChecklistDetailsFilterRows(filterState, dashboardFilters.allFilters, gridItems)
          : gridItems
      );
    }
  }, [gridItems]);

  const filterItems = (newFilterState?: FilterState) => {
    if (!gridItems) return;
    if (isNotRefresh) {
      setFilterState(dashboardCache);
      setIsNotRefresh(false);
      return;
    }
    if (newFilterState) {
      setFilterState(newFilterState);
      setFilteredItems(
        newFilterState.filterBar
          ? JEMChecklistDetailsFilterRows(newFilterState, dashboardFilters.allFilters, gridItems)
          : gridItems
      );
    } else {
      setFilteredItems(gridItems);
    }
  };

  const getClassName = (item: {
    taskType: string;
    status: string;
    jeType: string;
    docNo: string;
    publishedState: string;
  }) => {
    const className = `${item.taskType.toLowerCase()}--${
      item.status == "Completed"
        ? kebabCase(item.status)
        : item.jeType == "Draft JE" && item.docNo
        ? kebabCase(item.jeType)
        : kebabCase(item.publishedState)
    }`;
    return className;
  };

  const checklistName = chkDetailsPageState ? chkDetailsPageState.fcwChecklistName : "";
  const checklistPeriod = chkDetailsPageState ? chkDetailsPageState.fiscalPeriod : "";
  const backupReviewers = chkDetailsPageState ? chkDetailsPageState.backupReviewer : [];
  const backupPosters = chkDetailsPageState ? chkDetailsPageState.backupPoster : [];
  const companyCodes = chkDetailsPageState ? chkDetailsPageState.companyCodes : [];
  return (
    <div className={PageStyles.rootDiv}>
      <JEMChecklistDetailsHeader
        onNavigateBack={() => {
          if (props.onNavigateBack) {
            props.onNavigateBack(location.state);
          }
        }}
        loadingStatus={loadingStatus}
        checklistName={checklistName}
        checklistPeriod={checklistPeriod}
      />
      {!pageState.message ? null : (
        <div
          className={css`
            padding: 16px;
            padding-top: 0px;
          `}
        >
          <MessageBar
            messageBarType={pageState.message.type}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            onDismiss={() => {
              setPageState({ ...pageState, message: null });
            }}
          >
            {pageState.message.message}
          </MessageBar>
        </div>
      )}
      {loadingStatus !== LoadingStatus.Pending && gridItems && gridItems.length == 0 ? (
        <Text
          variant="xLarge"
          style={{
            color: "var(--accent-font-color, black)"
          }}
        >
          <JEMChecklistDetailsCommandBar
            onEditClick={onEditClick}
            checklistGUID={searchParams.get("checklistRefGuid") as string}
            onChecklistCloneClicked={onCloneChecklistClick}
            onPublishChecklistClicked={onPublishChecklistClick}
            onBulkEditClicked={onBulkEditChecklistClick}
            onImportTasksClicked={onImportTasksClicked}
            onNoActivityChecklistClicked={onNoActivityChecklistClick}
            onBulkDeleteChecklistClicked={onBulkDeleteChecklistClick}
            onRefreshChecklistDetailsClicked={onRefreshClick}
            disableSignOff={disableSignOff}
            onSignOffClicked={onSignOffClick}
            backupReviewers={backupReviewers}
            backupPosters={backupPosters}
            companyCodes={companyCodes}
            onReopenClicked={onReopenClick}
            disableReopen={disableReopen}
            disableAllButSignOff={disableAllButSignOff}
          />
          No Items to show.
        </Text>
      ) : loadingStatus === LoadingStatus.Pending ? (
        <LoadingSpinner label={`Loading Checklists Details`} />
      ) : _error ? (
        <Text
          variant="xLarge"
          style={{
            color: "var(--accent-font-color, black)"
          }}
        >
          Error Loading Checklists Details - {_error.message}
        </Text>
      ) : (
        <>
          <div
            className={css`
              position: relative;
              flex-grow: 1;
              width: 100%;
            `}
          >
            <DashboardFilter<JEMChecklistDetailsRow>
              filters={dashboardFilters.allFilters}
              savedFilters={dashboardCache.filterBar}
              singleSelectionFilters={dashboardFilters.singleSelectionFilters}
              searchEnabledFilters={dashboardFilters.searchEnabledFilters}
              onFilterChanged={filterItems}
              loadingStatus={LoadingStatus.Resolved}
              tilesAndFilters={{
                currentTile: "",
                tiles: OrderedMap<string, IIndexedTile>({}),
                gridItems: gridItems || [],
                searchCriteria: dashboardCache && dashboardCache.searchCriteria
              }}
              localStorageKey={`${LocalStorageKeys?.checklistDetailsFilterState}`}
            />
          </div>
          <div
            className={css`
              display: inline-flex;
              gap: 16px;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              align-items: stretch;
              width: 100%;
              margin: 16px 0 16px 0;

              // TODO: CREATED A "IS POSTED" STATUS
              .checklist-task {
                // amber
                .standard--draft {
                  background: rgba(255, 251, 235, 1);
                  &:hover {
                    background: rgba(254, 243, 199, 1);
                  }
                  &.is-selected {
                    background: rgba(253, 230, 138, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(252, 211, 77, 0.7);
                  }
                }
                // emerald
                .standard--published {
                  background: rgba(236, 253, 245, 1);
                  &:hover {
                    background: rgba(209, 250, 229, 1);
                  }
                  &.is-selected {
                    background: rgba(167, 243, 208, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(110, 231, 183, 0.7);
                  }
                }
                // stone
                .standard--no-activity {
                  background: rgba(250, 250, 249, 1);
                  &:hover {
                    background: rgba(245, 245, 244, 1);
                  }
                  &.is-selected {
                    background: rgba(245, 245, 244, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(214, 211, 209, 0.7);
                  }
                }
                // indigo
                .adhoc--draft {
                  background: rgba(238, 242, 255, 1);
                  &:hover {
                    background: rgba(224, 231, 255, 1);
                  }
                  &.is-selected {
                    background: rgba(199, 210, 254, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(165, 180, 252, 0.7);
                  }
                }
                // teal
                .adhoc--published {
                  background: rgba(240, 253, 250, 1);
                  &:hover {
                    background: rgba(204, 251, 241, 1);
                  }
                  &.is-selected {
                    background: rgba(153, 246, 228, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(94, 234, 212, 0.7);
                  }
                }
                // gray
                .adhoc--no-activity {
                  background: rgba(249, 250, 251, 1);
                  &:hover {
                    background: rgba(243, 244, 246, 1);
                  }
                  &.is-selected {
                    background: rgba(229, 231, 235, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(209, 213, 219, 0.7);
                  }
                }
                // gray
                .standard--completed {
                  background: rgba(249, 250, 251, 1);
                  &:hover {
                    background: rgba(243, 244, 246, 1);
                  }
                  &.is-selected {
                    background: rgba(229, 231, 235, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(209, 213, 219, 0.7);
                  }
                }
                // gray
                .adhoc--completed {
                  background: rgba(249, 250, 251, 1);
                  &:hover {
                    background: rgba(243, 244, 246, 1);
                  }
                  &.is-selected {
                    background: rgba(229, 231, 235, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(209, 213, 219, 0.7);
                  }
                }
                // gray
                .adhoc--draft-je {
                  background: rgba(249, 250, 251, 1);
                  &:hover {
                    background: rgba(243, 244, 246, 1);
                  }
                  &.is-selected {
                    background: rgba(229, 231, 235, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(209, 213, 219, 0.7);
                  }
                }
                .standard--draft-je {
                  background: rgba(249, 250, 251, 1);
                  &:hover {
                    background: rgba(243, 244, 246, 1);
                  }
                  &.is-selected {
                    background: rgba(229, 231, 235, 0.7);
                  }
                  &.is-selected:hover {
                    background: rgba(209, 213, 219, 0.7);
                  }
                }
              }
            `}
          >
            <DashboardGrid
              idForLocalStorage={"jemChecklistDetailsConfiguration"}
              columnGenerator={columnGenerator}
              isSortedIndex={10}
              items={filteredItems}
              isSortedDescending={true}
              customRef={dashboardGridRef}
              selectionMode={SelectionMode.multiple}
              height={`500px`}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "jemChecklistDetails",
                  rowsWithHeader: rows,
                  fileName: "jemChecklistDetails.xlsx"
                });
              }}
              onRenderRow={(props, defaultRender) => {
                if (!defaultRender || !props) return null;

                return (
                  <div className="checklist-task">
                    {defaultRender({
                      ...props,
                      className: getClassName(props.item)
                    })}
                  </div>
                );
              }}
            >
              <JEMChecklistDetailsCommandBar
                onEditClick={onEditClick}
                checklistGUID={searchParams.get("checklistRefGuid") as string}
                onChecklistCloneClicked={onCloneChecklistClick}
                onPublishChecklistClicked={onPublishChecklistClick}
                onBulkEditClicked={onBulkEditChecklistClick}
                onImportTasksClicked={onImportTasksClicked}
                onNoActivityChecklistClicked={onNoActivityChecklistClick}
                onBulkDeleteChecklistClicked={onBulkDeleteChecklistClick}
                onRefreshChecklistDetailsClicked={onRefreshClick}
                onSignOffClicked={onSignOffClick}
                disableSignOff={disableSignOff}
                backupReviewers={backupReviewers}
                backupPosters={backupPosters}
                companyCodes={companyCodes}
                onReopenClicked={onReopenClick}
                disableReopen={disableReopen}
                disableAllButSignOff={disableAllButSignOff}
              />
            </DashboardGrid>
          </div>
        </>
      )}

      {pageState.editItem !== null && (
        <JEMChecklistDetailsEditPanel
          configuration={props.configuration}
          loading={pageState.loadingSave}
          error={pageState.errorSave}
          checklistItem={pageState.editItem}
          checklistPeriod={checklistPeriod}
          onClosePanel={() => {
            closePanel();
          }}
          onSaveItem={async (editedItem: JEMChecklistDetailsRow) => {
            setPageState({
              ...pageState,
              loadingSave: true
            });
            try {
              const poster = editedItem.assignedPoster;
              const backupPoster = editedItem.backupPoster;
              const backupReviewer = editedItem.assignedReviewer;
              if (poster || backupReviewer.length !== 0 || backupReviewer.length !== 0) {
                const validatedUsers = await checkUsersPermissions(
                  {
                    configuration: props.configuration.GeneralLedgerApi,
                    accessToken: userContext.accessToken
                  },
                  poster,
                  undefined,
                  backupPoster,
                  backupReviewer
                );
                if (typeof validatedUsers === "string") {
                  setPageState({
                    ...pageState,
                    loadingSave: false,
                    errorSave: [validatedUsers]
                  });
                  return;
                }
              }

              await saveJEChecklistTask(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                editedItem,
                domainData[DomainDataEnum.JeReasonCodes]
              );
              setPageState({
                ...pageState,
                loadingSave: false
              });
              run(
                getChecklistDetails(
                  props.configuration.GeneralLedgerApi,
                  userContext.accessToken,
                  searchParams.get("checklistRefGuid") as string,
                  location.state as CustomizedState | null,
                  domainData
                )
              );
              closePanel();
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingSave", "errorSave", error);
            }
          }}
        />
      )}

      {pageState.openCloneChecklistPanel && (
        <JEMChecklistDetailsClonePanel
          configuration={props.configuration.GeneralLedgerApi}
          loading={pageState.loadingClone}
          previousCloneResponse={pageState.cloneResponse}
          error={pageState.errorClone}
          checklistRefGUIDSource={searchParams.get("checklistRefGuid") as string}
          checklistNameSource={checklistName}
          checklistPeriodSource={checklistPeriod}
          allItems={gridItems || []}
          checklistItems={pageState.selectedRows || []}
          domainData={
            jemContext.initInfo.values ||
            ({
              FiscalPeriods: [],
              PostingDueDates: []
            } as Pick<DomainDataObjects, DomainDataEnum.PostingDueDates | DomainDataEnum.FiscalPeriods>)
          }
          onClosePanel={() => {
            closeClonePanel(null);
          }}
          onSaveItem={async (
            checklistRefGUIDSource: string,
            checklistRefGUIDTarget: string,
            taskRefGUIDs: string[]
          ) => {
            setPageState({
              ...pageState,
              loadingClone: true
            });

            try {
              const response = await cloneJEMChecklist(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                checklistRefGUIDSource,
                checklistRefGUIDTarget,
                taskRefGUIDs
              );

              if (!response) {
                setPageState({
                  ...pageState,
                  errorClone: ["No response from server"],
                  loadingClone: false
                });
              }

              setPageState({
                ...pageState,
                cloneResponse: response,
                loadingClone: false
              });
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingClone", "errorClone", error);
            }
          }}
        />
      )}

      {pageState.openBulkEditChecklistPanel && (
        <BulkEdit
          selectedItems={pageState.selectedRows?.map((i) => i.refGuid).join(",") || ""}
          selectedItemsCount={pageState.selectedRows?.length || 0}
          loading={pageState.loadingBulkEdit}
          hideBulkEditModal={() =>
            setPageState({
              ...pageState,
              openBulkEditChecklistPanel: false,
              errorBulkEdit: [],
              selectedRows: []
            })
          }
          checklistPeriod={FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
            checklistPeriod
          )}
          getFormInputs={getFormInputConfig}
          onSaveItem={async (bulkEditInput: TaskBulkEditInputDto) => {
            setPageState({
              ...pageState,
              loadingBulkEdit: true
            });
            try {
              const response = await bulkEditJEMChecklistTasks(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                searchParams.get("checklistRefGuid") as string,
                bulkEditInput
              );
              setPageState({
                ...pageState,
                loadingBulkEdit: false
              });
              if (response === true) {
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
                closeBulkEditPanel();
              } else {
                setPageState({
                  ...pageState,
                  message: {
                    message: "One or more error occured while performing Bulk edit activity. Please try again.",
                    type: MessageBarType.error
                  },
                  openBulkEditChecklistPanel: false
                });

                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
              }
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingBulkEdit", "errorBulkEdit", error);
            }
          }}
        />
      )}

      {pageState.openPublishChecklistPanel && (
        <JEMChecklistDetailsPublishPanel
          checklistItems={pageState.selectedRows || []}
          checklistName={checklistName}
          checklistPeriod={checklistPeriod}
          checklistRefGUID={searchParams.get("checklistRefGuid") as string}
          loading={pageState.loadingPublish}
          error={pageState.errorPublish}
          onClosePanel={() => {
            closePublishPanel();
          }}
          onSaveItem={async (checklistItems: JEMChecklistDetailsRow[]) => {
            setPageState({
              ...pageState,
              loadingPublish: true
            });
            //do something call api
            const refGUIDs = checklistItems.map((item) => item.refGuid) as string[];
            try {
              const response = await publishJEMChecklistTasks(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                searchParams.get("checklistRefGuid") as string,
                refGUIDs
              );
              setPageState({
                ...pageState,
                loadingPublish: false
              });
              if (response.status === true) {
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
                closePublishPanel();
              } else {
                const manyErrorsFirstError = "One or more operations failed";
                const errorMessage = parsePublishOrNoActivityResponse(response, checklistItems, manyErrorsFirstError);

                setPageState({
                  ...pageState,
                  errorPublish: errorMessage
                });
              }
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingPublish", "errorPublish", error);
            }
          }}
        />
      )}

      {pageState.openBulkDeleteChecklistPanel && (
        <JEMChecklistDetailsBulkDeletePanel
          checklistItems={pageState.selectedRows || []}
          checklistName={checklistName}
          checklistPeriod={checklistPeriod}
          checklistRefGUID={searchParams.get("checklistRefGuid") as string}
          loading={pageState.loadingBulkDelete}
          error={pageState.errorBulkDelete}
          onClosePanel={() => {
            closeBulkDeletePanel();
          }}
          onSaveItem={async (checklistItems: JEMChecklistDetailsRow[]) => {
            setPageState({
              ...pageState,
              loadingBulkDelete: true
            });
            //do something call api
            const refGUIDs = checklistItems.map((item) => item.refGuid) as string[];
            try {
              const response = await bulkDeleteJEMChecklistTasks(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                searchParams.get("checklistRefGuid") as string,
                refGUIDs
              );
              setPageState({
                ...pageState,
                loadingBulkDelete: false
              });
              if (response.status === true) {
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
                setPageState({
                  ...pageState,
                  message: {
                    message: response.message,
                    type: MessageBarType.success
                  },
                  openBulkDeleteChecklistPanel: false,
                  errorBulkDelete: [],
                  selectedRows: []
                });
              } else {
                const manyErrorsFirstError = "One or more operations failed ";
                const responseMessage = response.message;
                const errorMessage = parsePublishOrNoActivityResponse(
                  response,
                  checklistItems,
                  manyErrorsFirstError + responseMessage
                );

                setPageState({
                  ...pageState,
                  errorBulkDelete: errorMessage
                });
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
              }
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingBulkDelete", "errorBulkDelete", error);
            }
          }}
        />
      )}

      {pageState.openNoActivityChecklistPanel && (
        <JEMChecklistDetailsNoActivityPanel
          loading={pageState.loadingNoActivity}
          error={pageState.errorNoActivity}
          onClosePanel={() => {
            closeNoActivityPanel();
          }}
          onSaveItem={async (checklistItems: JEMChecklistDetailsRow[], noActivityReason: string, comments: string) => {
            setPageState({
              ...pageState,
              loadingNoActivity: true
            });

            try {
              const response = await markNoActivityWithPayload(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                searchParams.get("checklistRefGuid") as string,
                checklistItems,
                noActivityReason,
                comments
              );
              setPageState({
                ...pageState,
                loadingNoActivity: false
              });
              if (response.status === true) {
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
                closeNoActivityPanel();
              } else {
                const manyErrorsFirstError = "One or more operations failed";
                const errorMessage = parsePublishOrNoActivityResponse(response, checklistItems, manyErrorsFirstError);

                setPageState({
                  ...pageState,
                  errorNoActivity: errorMessage
                });
              }
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingNoActivity", "errorNoActivity", error);
            }
          }}
          checklistItems={pageState.selectedRows || []}
          checklistName={checklistName}
          checklistPeriod={checklistPeriod}
          checklistRefGUID={searchParams.get("checklistRefGuid") as string}
        />
      )}
      {pageState.deleteItem !== null && (
        <JEMChecklistDetailsDeletePanel
          loading={pageState.loadingDelete}
          error={pageState.errorDelete}
          onClosePanel={() => {
            closeDeletePanel();
          }}
          onSaveItem={async (checklistItem: JEMChecklistDetailsRow) => {
            setPageState({
              ...pageState,
              loadingDelete: true
            });
            try {
              const response = await deleteJEChecklistTask(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                checklistItem
              );
              setPageState({
                ...pageState,
                loadingDelete: false
              });

              if (response.status === false) {
                setPageState({
                  ...pageState,
                  errorDelete: [response.errorCode + response.message]
                });
              } else {
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
                setPageState({
                  ...pageState,
                  message: {
                    message: response.messaage,
                    type: MessageBarType.success
                  }
                });
                closeDeletePanel();
              }
            } catch (error) {
              handleChecklistError(setPageState, pageState, "loadingDelete", "errorDelete", error);
            }
          }}
          checklistItem={pageState.deleteItem}
        />
      )}
      {pageState.openImportTasksPanel && (
        <JEMChecklistDetailsImportTasksPanel
          checklistName={checklistName}
          checklistPeriod={checklistPeriod}
          checklistRefGUID={searchParams.get("checklistRefGuid") as string}
          configuration={props.configuration}
          onClosePanel={() => {
            setPageState({
              ...pageState,
              openImportTasksPanel: false
            });
          }}
          onImportTasks={async (message) => {
            setPageState({
              ...pageState,
              message: message,
              openImportTasksPanel: false
            });
            onRefreshClick();
          }}
        />
      )}
      {pageState.openChecklistSignOffPanel && (
        <JEMChecklistDetailsSignOffPanel
          checklistItems={gridItems ?? []}
          checklistName={checklistName}
          actualTimeTaken={pageState.actualTimeTaken}
          checklistPeriod={checklistPeriod}
          signoffMode={pageState.signoffMode ?? SignoffMode.SignOff}
          error={pageState.errorChecklistSignOff}
          missedSLAReasons={pageState.missedSLAOptions}
          onClosePanel={closeSignOffPanel}
          onSignOff={async (
            newStatus: SignoffStatus,
            missedSLAReasonId: number,
            comment: string,
            actualTimeTaken: number
          ) => {
            setPageState({ ...pageState, loadingChecklistSignOffPanel: true });
            const tasks: string[] = gridItems.map((t) => t.taskId) ?? [];

            try {
              const response = await signOffJEChecklist(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                searchParams.get("checklistRefGuid") as string,
                tasks,
                newStatus,
                missedSLAReasonId,
                comment,
                actualTimeTaken
              );

              setPageState({ ...pageState, loadingChecklistSignOffPanel: false });

              if (response.status === true) {
                if (
                  response.data.findIndex(
                    (element: { errorCode: unknown; errorDescription: unknown }) =>
                      element.errorCode || element.errorDescription
                  ) >= 0
                ) {
                  throw new ApiError(response.data[0].errorDescription);
                }
                run(
                  getChecklistDetails(
                    props.configuration.GeneralLedgerApi,
                    userContext.accessToken,
                    searchParams.get("checklistRefGuid") as string,
                    location.state as CustomizedState | null,
                    domainData
                  )
                );
                closeSignOffPanel();
              } else {
                setPageState({
                  ...pageState,
                  errorChecklistSignOff: [response.message]
                });
              }
            } catch (error) {
              handleChecklistError(
                setPageState,
                pageState,
                "loadingChecklistSignOffPanel",
                "errorChecklistSignOff",
                error
              );
            }
          }}
          getMissedSLAOptions={async () => {
            try {
              setPageState({ ...pageState, loadingChecklistSignOffPanel: true });
              const response = await getTaskPropertyFromFCCW(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                FCCWTaskProperty.MissedSlaReason
              );

              setPageState({
                ...pageState,
                loadingChecklistSignOffPanel: false,
                missedSLAOptions: response.map((option: { isActive: boolean; id: number; name: string }) => {
                  if (option.isActive) {
                    return { key: option.id, text: option.name };
                  }
                })
              });
            } catch (error) {
              handleChecklistError(
                setPageState,
                pageState,
                "loadingChecklistSignOffPanel",
                "errorChecklistSignOff",
                error
              );
            }
          }}
          getReasonReactivatedOptions={async () => {
            try {
              setPageState({ ...pageState, loadingChecklistSignOffPanel: true });
              const response = await getTaskPropertyFromFCCW(
                props.configuration.GeneralLedgerApi,
                userContext.accessToken,
                FCCWTaskProperty.ReasonReactivated
              );

              setPageState({
                ...pageState,
                loadingChecklistSignOffPanel: false,
                missedSLAOptions: response.taskPropertyByName.values.map(
                  (option: { isActive: boolean; id: number; name: string }) => {
                    if (option.isActive) {
                      return { key: option.id, text: option.name };
                    }
                  }
                )
              });
            } catch (error) {
              handleChecklistError(
                setPageState,
                pageState,
                "loadingChecklistSignOffPanel",
                "errorChecklistSignOff",
                error
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default JEMChecklistDetailsPage;
