import { BaseRouteProps } from "@jem/components";
import React from "react";
import { Route, Routes } from "react-router-dom";
import GLBatch from "./GeneralLedger/pages/GLBatchJE/GLBatch";
import GLBatchSearch from "./GeneralLedger/pages/GLBatchSearch/GLBatchSearch";
import GLCreate from "./GeneralLedger/pages/GLCreate/GLCreate";
import GLDashboardPage from "./GeneralLedger/pages/GLDashboard/GLDashboardPage";
import GLDetails from "./GeneralLedger/pages/GLDetails/GLDetails";
import GLNumberSearch from "./GeneralLedger/pages/GLNumberSearch/GLNumberSearch";
import GLSearch from "./GeneralLedger/pages/GLSearch/GLSearch";

const GeneralLedger: React.FC<BaseRouteProps> = (props) => {
  return (
    <>
      <Routes>
        <Route path="/create" element={<GLCreate configuration={props.config}></GLCreate>} />
        <Route path="/dualCurrency" element={<GLCreate configuration={props.config} templateId={"4"}></GLCreate>} />
        <Route
          path="/dashboard"
          element={
            <GLDashboardPage
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
            ></GLDashboardPage>
          }
        />
        <Route
          path="/details"
          element={
            <GLDetails
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
            ></GLDetails>
          }
        />
        <Route
          path="/batch"
          element={
            <GLBatch
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
            ></GLBatch>
          }
        />
        <Route
          path="/search/batch"
          element={
            <GLBatchSearch
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
            ></GLBatchSearch>
          }
        />


        <Route
          path="/search"
          element={
            <GLSearch
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
              environment={props.config.environment}
            ></GLSearch>
          }
        />
        <Route
          path="/search/number"
          element={
            <GLNumberSearch
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
            ></GLNumberSearch>
          }
        />
      </Routes>
    </>
  );
};

GeneralLedger.displayName = "GeneralLedger";
export default GeneralLedger;
